import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'

import { Typography } from '@mui/material'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { api, TokenImportMappings } from '@shared/api/src'
import { ActionModal, HasPermission, UserNotAllowed } from '@shared/components'
import {
  useFtpPortalHubCommunication,
  useEnforceLogin,
  useAuthorization,
  useNotification,
} from '@shared/hooks'
import { EnumServiceName } from '@shared/types'
import { formatDate, DataSource } from '@shared/utils'

export default function TokenImportMappingGrid() {
  const { t } = useTranslation()
  const { setNotification } = useNotification()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { user } = useEnforceLogin()
  const { userPermissionSet } = useAuthorization()
  const [selectedDate, setSelectedDate] = useState<string>(null)
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false)
  const [mappingToDelete, setMappingToDelete] = useState(null)
  const [loading, setLoading] = useState(false)
  const guidingId = 'tokenimportmapping'

  const gridRef = useRef<AgGridReact>(null)

  const TemplatesGridPrivs = ['v2admin.tokenimportmappings.get']

  useEffect(() => {
    setAppBarTitle(t('common.templates'), null, [
      t('common.settings'),
      t('common.token-import'),
    ])
  }, [])

  const datasource = useMemo(() => {
    return new DataSource('token-import-mappings')
  }, [])

  const columnDefs: ColDef<any>[] = useMemo(
    () => [
      {
        headerName: t('common.name'),
        field: 'mapping_name',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.date-generated'),
        field: 'created_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return formatDate(timestamp, user?.tz) || '-'
        },
        sortable: true,
        filterParams: {
          type: 'past',
        },
      },
    ],
    [userPermissionSet]
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const handleDeleteMapping = async () => {
    setLoading(true)
    try {
      await api.service('token-import-mappings').remove(mappingToDelete)
      gridRef.current.api.setGridOption('datasource', datasource)
      setIsDeleteModalOpen(false)

      setNotification({
        type: 'success',
        label: t('ftp-feature-mfe-token.token-import.mapping-deleted'),
      })
    } catch (error) {
      console.error('Error deleting mapping:', error)
      setNotification({
        type: 'error',
        label: t('ftp-feature-mfe-token.token-import.delete-failed'),
      })
    } finally {
      setLoading(false)
    }
  }

  const threeDotsOptions = useCallback((data: TokenImportMappings) => {
    const handleDelete = () => {
      setMappingToDelete(data?.id)
      setIsDeleteModalOpen(true)
    }

    return [
      {
        label: t('ftp-feature-mfe-token.token-import.delete-template'),
        action: handleDelete,
        enabled: true,
        guidingId: `${guidingId}-delete`,
      },
    ]
  }, [])

  return (
    <section>
      <HasPermission
        allPermissions={TemplatesGridPrivs}
        unauthorizedComponent={<UserNotAllowed />}
      >
        <PaginationTable<TokenImportMappings>
          getRowId={(data) => data.data.id}
          columnDefs={columnDefs}
          rowModelType={'serverSide'}
          serverSideDatasource={datasource}
          defaultColDef={defaultColDef}
          onRowClicked={null}
          getGridRef={getGridRef}
          serviceName={EnumServiceName.TokenImportMappings}
          guidingId={`${guidingId}`}
          threeDotsOptions={threeDotsOptions}
        />
      </HasPermission>

      <ActionModal
        open={isDeleteModalOpen}
        title={t('ftp-feature-mfe-token.token-import.delete-template')}
        buttons={[
          {
            testId: 'cancel-button',
            label: t('common.cancel'),
            color: 'secondary',
            onClick: () => setIsDeleteModalOpen(false),
            guidingId: `${guidingId}-cancel`,
          },
          {
            testId: 'submit-button',
            label: t('common.yes-delete'),
            isLoading: loading,
            onClick: handleDeleteMapping,
            guidingId: `${guidingId}-confirm`,
          },
        ]}
        onClose={() => setIsDeleteModalOpen(false)}
        guidingId={`${guidingId}`}
      >
        <Typography sx={{ fontSize: '14px' }} variant="body2">
          {t('common.modal.are-you-sure')}{' '}
          <strong>
            {t('ftp-feature-mfe-token.token-import.delete-template')}
          </strong>
          ?
        </Typography>
      </ActionModal>
    </section>
  )
}
