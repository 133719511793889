import { ColDef } from 'ag-grid-community'
import { AgGridReact } from 'ag-grid-react'
import { useCallback, useEffect, useMemo, useRef, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Tabs, Tab, AppBar } from '@mui/material'

import DateRangeFilter from '@shared/ag-grid/pagination-table/filters/date-range-filter/DateRangeFilter'
import PaginationTable from '@shared/ag-grid/pagination-table/PaginationTable'
import { api, TokenImport } from '@shared/api/src'
import {
  HasPermission,
  UserNotAllowed,
  Button,
  TokenImportStatus,
} from '@shared/components'
import {
  useAuthorization,
  useEnforceLogin,
  useFtpPortalHubCommunication,
} from '@shared/hooks'
import { NoTokensImported } from '@shared/icons'
import { EnumServiceName } from '@shared/types'
import { tokenImportCodes } from '@shared/types/tokenImportStatusCodes'
import {
  checkAllPermissions,
  checkPermission,
  downloadFile,
  DataSource,
  formatDatetime,
} from '@shared/utils'

import TokenImportMappingGrid from '../token-import-mapping-grid/TokenImportMappingGrid'

const useStyles = tss
  .withName('TokenImportMappingGrid')
  .create(({ theme }) => ({
    tabs: {
      backgroundColor: theme.palette.background.paper,
      color: theme.palette['neutral-900'],
      fontFamily: 'Inter',
      borderRadius: '2px',
      borderBottom: `1px solid ${theme.palette['neutral-300']}`,
    },
    noTokensContainer: {
      width: '472px',
      color: theme.palette['neutral-700'],
      textAlign: 'center',
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: '400',
      lineHeight: '20px',
    },
    noTokensIcon: {
      width: '62px',
      height: '62px',
    },
    importButton: {
      display: 'flex',
      width: '150px',
      height: '40px',
      padding: '8px 16px',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '8px',
      borderRadius: '6px',
      background: theme.palette['network-blue'],
      boxShadow: '0px 1px 2px 0px rgba(0, 0, 0, 0.05)',
      color: theme.palette.common.white,
      cursor: 'pointer',
      margin: '0 auto',
    },
    whiteContainer: {
      display: 'flex',
      padding: '24px',
      flexDirection: 'column',
      justifyContent: 'center',
      alignItems: 'center',
      gap: '24px',
      alignSelf: 'stretch',
      borderRadius: '6px',
      background: theme.palette.background.paper,
    },
  }))

export default function TokenImportGrid() {
  const { t } = useTranslation()
  const navigate = useNavigate()
  const { classes } = useStyles()
  const { setAppBarTitle } = useFtpPortalHubCommunication()
  const { userPermissionSet } = useAuthorization()
  const { user } = useEnforceLogin()
  const gridRef = useRef<AgGridReact>(null)
  const [selectedDate, setSelectedDate] = useState<string>(null)
  const [value, setValue] = useState(0)
  const [hasData, setHasData] = useState(true)
  const [searched, setSearched] = useState(false)
  const guidingId = 'tokenimport'

  const TokenImportGridPrivs = ['v2admin.tokenimports.get']

  const filterTokenImportStatusValues = Object.entries(tokenImportCodes).map(
    ([key, value]) => ({ value: key, label: value })
  )

  useEffect(() => {
    setAppBarTitle(t('common.token-import'), null, t('common.settings'))
  }, [setAppBarTitle, t])

  const datasource = useMemo(() => {
    return new DataSource('token-imports')
  }, [])

  const columnDefs: ColDef<TokenImport>[] = useMemo(
    () => [
      {
        headerName: t('common.file'),
        field: 'filename',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
      {
        headerName: t('common.location'),
        field: 'location.name',
        floatingFilter: true,
      },
      {
        headerName: t('common.status'),
        field: 'status',
        floatingFilter: true,
        filter: 'agSetColumnFilter',
        filterParams: {
          values: filterTokenImportStatusValues.map((option) => option.value),
          valueFormatter: (params) => {
            const option = filterTokenImportStatusValues.find(
              (option) => option.value === params.value
            )
            return option ? option.label : params.value
          },
        },
        cellRenderer: (data) => <TokenImportStatus statusCode={data.value} />,
      },
      {
        headerName: t('common.time-started'),
        field: 'created_ts',
        floatingFilter: true,
        filter: DateRangeFilter,
        valueGetter: (params) => {
          const timestamp = params.data?.created_ts
          return formatDatetime(timestamp, user?.tz) || '-'
        },
        sortable: true,
        filterParams: {
          type: 'past',
        },
      },
      {
        headerName: t('common.records'),
        field: 'row_count',
        floatingFilter: true,
        filter: 'agTextColumnFilter',
        sortable: true,
      },
    ],
    [selectedDate, t, user?.tz, userPermissionSet]
  )

  const defaultColDef = useMemo(
    () => ({
      resizable: true,
    }),
    []
  )

  const getGridRef = useCallback((ref) => {
    gridRef.current = ref
  }, [])

  const handleChange = (event, newValue) => {
    setValue(newValue)
  }

  const onDataChange = (hasData) => {
    setHasData(hasData)
    setSearched(true)
  }

  const tabStyles = {
    textTransform: 'none',
    fontSize: '14px',
    fontFamily: 'Inter',
  }

  const tabStyle = (index) => ({
    ...tabStyles,
    border: value === index ? '1px solid #E5E7EB' : 'none',
    borderBottom: value === index ? 'none' : '',
    borderRadius: '6px 6px 0 0',
  })

  const threeDotsOptions = useCallback(
    (data: TokenImport) => {
      const handleDownload = async () => {
        const query = {
          filter: { token_import_id: data.id },
          page: {
            size: 5000,
            number: 1,
          },
          _format: 'csv',
        }

        const csvData = await api.service('tokens').export({
          paginate: true,
          query: {
            ...query,
          },
        })
        downloadFile(csvData, `${data.id}.csv`)
      }

      return [
        {
          label: t('common.details'),
          action: () => navigate(`/merchant/token-import/${data.id}/detail`),
          enabled:
            checkPermission(
              userPermissionSet,
              'v2admin.tokenimportdetails.get'
            ) && !(data.status === 'new' && !data.token_import_mapping_id),
          guidingId: `${guidingId}-details`,
        },
        {
          label: t(
            'ftp-feature-mfe-token.token-import.download-token-import-results'
          ),
          action: () => handleDownload(),
          enabled: data.status === 'processed',
          guidingId: `${guidingId}-download`,
        },
        {
          label: t('ftp-feature-mfe-token.token-import.edit-header-mappping'),
          action: () => navigate(`/merchant/token-import/${data.id}/mapping`),
          enabled: data.status === 'new',
          guidingId: `${guidingId}-edit`,
        },
      ]
    },
    [userPermissionSet]
  )

  return (
    <HasPermission
      allPermissions={TokenImportGridPrivs}
      unauthorizedComponent={<UserNotAllowed />}
    >
      {!searched && !hasData ? (
        <div className={classes.whiteContainer}>
          <div className={classes.noTokensContainer}>
            <NoTokensImported className={classes.noTokensIcon} />
            <div>
              {t('ftp-feature-mfe-token.token-import.no-tokens-imported')}
              <br />
              {t(
                'ftp-feature-mfe-token.token-import.click-button-add-first-token'
              )}
            </div>
            <div style={{ marginTop: '20px' }} />{' '}
            <Button
              className={classes.importButton}
              label={t('ftp-feature-mfe-token.token-import.import-token')}
              color="primary"
              onClick={() => navigate('/merchant/token-import/add')}
              guidingId={`${guidingId}-importtoken`}
            />
          </div>
        </div>
      ) : (
        <>
          <AppBar
            position="relative"
            elevation={0}
            sx={{ borderRadius: '4px' }}
          >
            <Tabs
              textColor="inherit"
              onChange={handleChange}
              value={value}
              className={classes.tabs}
              TabIndicatorProps={{
                style: { textTransform: 'none' },
                sx: { backgroundColor: '#D64123', fontFamily: 'Inter' },
              }}
              data-guiding-id={`${guidingId}-tabs`}
            >
              <Tab
                label={t('common.token-import')}
                sx={tabStyle(0)}
                data-guiding-id={`${guidingId}-tab-tokenimport`}
              />
              {checkAllPermissions(
                userPermissionSet,
                'v2admin.tokenimportmappings.get'
              ) && (
                <Tab
                  label={t('common.templates')}
                  sx={tabStyle(1)}
                  data-guiding-id={`${guidingId}-tab-templates`}
                />
              )}
            </Tabs>
          </AppBar>
          {value === 0 && (
            <PaginationTable<TokenImport>
              getRowId={(data) => data.data.id}
              columnDefs={columnDefs}
              rowModelType={'serverSide'}
              serverSideDatasource={datasource}
              defaultColDef={defaultColDef}
              onRowClicked={null}
              getGridRef={getGridRef}
              onDataChange={onDataChange}
              serviceName={EnumServiceName.TokenImports}
              primaryButtonData={{
                text: t('ftp-feature-mfe-token.token-import.import-token'),
                action: () => navigate('/merchant/token-import/add'),
              }}
              guidingId={`${guidingId}`}
              threeDotsOptions={threeDotsOptions}
            />
          )}
          {value === 1 &&
            checkAllPermissions(
              userPermissionSet,
              'v2admin.tokenimportmappings.get'
            ) && <TokenImportMappingGrid />}
        </>
      )}
    </HasPermission>
  )
}
