import React, { useState, useEffect, SetStateAction, Dispatch } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import { Modal, Box, Typography } from '@mui/material'

import { api } from '@shared/api/src'
import { ActionModal } from '@shared/components'
import Input from '@shared/components/form-fields/inputs/Input'
import Notification from '@shared/components/notification/Notification'
import { toArrayFieldErrors } from '@shared/utils'

interface SaveMappingModalProps {
  open: boolean
  handleClose: () => void
  locationId: string
  tokenImportId: string
  selectedHeaders: any
  handleDryRun: () => void
  onDryRunButtonDisabled: Dispatch<SetStateAction<boolean>>
  notifyParentError?: (error: string, detailedMessages: string[]) => void
  guidingId?: string
}

const useStyles = tss.withName('SaveMappingModal').create(({ theme }) => ({
  modalBox: {
    width: '706px',
  },
  description: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: '20px',
    marginBottom: '16px',
  },
  errorContainer: {
    marginTop: '16px',
  },
}))

export const SaveMappingModal: React.FC<SaveMappingModalProps> = ({
  open,
  handleClose,
  locationId,
  tokenImportId,
  selectedHeaders,
  handleDryRun,
  onDryRunButtonDisabled,
  notifyParentError,
  guidingId,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [mappingName, setMappingName] = useState<string>('')
  const [fieldMappingErrors, setFieldMappingErrors] = useState<string[]>([])
  const [mappingNameError, setMappingNameError] = useState<string>('')
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    if (!open) {
      setMappingName('')
      setFieldMappingErrors([])
      setMappingNameError('')
    }
  }, [open])

  const removeEmptyFields = (obj) => {
    return Object.fromEntries(
      Object.entries(obj)
        .filter(
          ([_, v]) =>
            v != null &&
            v !== '' &&
            (typeof v !== 'object' || Object.keys(v).length > 0)
        )
        .map(([k, v]) => [k, v === Object(v) ? removeEmptyFields(v) : v])
    )
  }

  const onSaveMapping = async () => {
    setFieldMappingErrors([])
    setMappingNameError('')

    const cleanedFieldMappings = removeEmptyFields(selectedHeaders)

    const payload = {
      mapping_name: mappingName,
      location_id: locationId,
      field_mappings: cleanedFieldMappings,
    }

    setIsLoading(true)

    try {
      const response = await api
        .service('token-import-mappings')
        .create(payload)

      await api.service('token-imports').patch(tokenImportId, {
        token_import_mapping_id: response.id,
      })
      onDryRunButtonDisabled(false)
      handleClose()
      handleDryRun()
    } catch (error) {
      const errors = toArrayFieldErrors(error)

      if (errors.length) {
        const fieldMappingErrors = errors
          .filter(
            (error) =>
              error.field === 'field_mappings' ||
              error.field === 'token_import_mapping_id'
          )
          .map((error) => error.message)

        if (fieldMappingErrors.length) {
          const errorType =
            errors[0].field === 'field_mappings'
              ? 'Error saving mapping'
              : 'Error applying mapping'

          setFieldMappingErrors(fieldMappingErrors)
          notifyParentError && notifyParentError(errorType, fieldMappingErrors)
          handleClose()
          return
        }

        const mappingNameErrors = errors
          .filter((error) => error.field === 'mapping_name')
          .map((error) => error.message)

        if (mappingNameErrors.length) {
          setMappingNameError(mappingNameErrors.join(', '))
        }
      } else {
        const message = error?.message || 'An unexpected error occurred'
        setFieldMappingErrors([message])
        notifyParentError && notifyParentError(message, [])
        handleClose()
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      title={t('ftp-feature-mfe-token.token-import.save-mapping')}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: handleClose,
          guidingId: `${guidingId}-cancel`,
        },
        {
          label: t('ftp-feature-mfe-token.save-dry-run-import'),
          onClick: onSaveMapping,
          disabled: !mappingName || isLoading,
          guidingId: `${guidingId}-save`,
        },
      ]}
      className={classes.modalBox}
      guidingId={guidingId}
    >
      <Typography
        id="save-mapping-modal-description"
        className={classes.description}
      >
        {t('ftp-feature-mfe-token.template-generated-reuse')}
      </Typography>
      <Input
        testId="name-template"
        label={t('ftp-feature-mfe-token.enter-name-for-template')}
        placeholder={t('common.name-placeholder')}
        value={mappingName}
        onChange={(e) => {
          setMappingName(e.target.value)
          setMappingNameError('')
        }}
        error={!!mappingNameError}
        helperText={mappingNameError}
        required
        guidingId={`${guidingId}-input`}
      />

      {fieldMappingErrors.length > 0 && (
        <Box className={classes.errorContainer}>
          {fieldMappingErrors.map((message, index) => (
            <Notification
              key={index}
              label={message}
              type="error"
              show={true}
              onClose={() => setFieldMappingErrors([])}
            />
          ))}
        </Box>
      )}
    </ActionModal>
  )
}

export default SaveMappingModal
