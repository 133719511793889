import { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { tss } from 'tss-react/mui'

import {
  Box,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from '@mui/material'

import { api } from '@shared/api/src'
import { ActionModal, Loading } from '@shared/components'
import { toArrayFieldErrors } from '@shared/utils'

const useStyles = tss.withName('ApplyMappingModal').create(({ theme }) => ({
  modal: {
    width: '706px',
  },
  radioGroupContainer: {
    overflowY: 'auto',
    flex: 1,
    maxHeight: '150px',
    paddingLeft: '10px',
  },
  radioGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '12px',
  },
  radioItem: {
    display: 'flex',
    alignItems: 'center',
    gap: '12px',
  },
  labelContainer: {
    display: 'flex',
    alignItems: 'center',
    gap: '24px',
  },
  labelText: {
    color: theme.palette['neutral-700'],
    fontFamily: 'Inter',
    fontSize: '14px',
    fontStyle: 'normal',
    fontWeight: 400,
    lineHeight: 'normal',
  },
}))

export const ApplyMappingModal = ({
  open,
  handleClose,
  availableColumns,
  onApply,
  notifyParentError,
  guidingId,
}) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const [selectedValue, setSelectedValue] = useState(null)
  const [mappings, setMappings] = useState([])
  const [loading, setLoading] = useState(true)
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    const fetchMappings = async () => {
      try {
        const response = await api.service('token-import-mappings').find()

        setMappings(response)
      } catch (error) {
        console.error(error)
      } finally {
        setLoading(false)
      }
    }

    fetchMappings()
  }, [])

  const handleSelectionChange = (event) => {
    const selectedMapping = mappings.find(
      (mapping) => mapping.id === event.target.value
    )
    setSelectedValue(selectedMapping)
  }

  const isFieldValid = (field) => {
    if (typeof field === 'object') {
      return Object.values(field).every(isFieldValid)
    }
    return availableColumns.includes(field)
  }

  const isMappingValid = (fieldMappings) => {
    const allFields = [
      ...(fieldMappings?.contact ? Object.values(fieldMappings.contact) : []),
      ...(fieldMappings?.token ? Object.values(fieldMappings.token) : []),
    ].flat()
    return allFields.every(isFieldValid)
  }

  const mappingsWithValidity = mappings.map((mapping) => ({
    ...mapping,
    isValid: isMappingValid(mapping.field_mappings),
  }))

  const handleApply = async () => {
    setIsLoading(true)
    try {
      await onApply(selectedValue)
    } catch (error) {
      const errors = toArrayFieldErrors(error)
      const fieldMappingErrors = errors
        .filter((error) => error.field === 'token_import_mapping_id')
        .map((error) => error.message)

      if (fieldMappingErrors.length > 0) {
        const customMessage = fieldMappingErrors.includes(
          'Token import mapping not found'
        )
          ? t('ftp-feature-mfe-token.token-import.you-cant-assign-this-mapping')
          : fieldMappingErrors.join(', ')

        notifyParentError(customMessage, [customMessage])
      } else {
        notifyParentError('An unknown error occurred', [])
      }
    } finally {
      setIsLoading(false)
    }
  }

  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      title={t('ftp-feature-mfe-token.token-import.apply-mapping-template')}
      subtitle={t(
        'ftp-feature-mfe-token.token-import.select-mapping-template-created'
      )}
      buttons={[
        {
          label: t('common.cancel'),
          onClick: handleClose,
          color: 'secondary',
          guidingId: `${guidingId}-cancel`,
        },
        {
          label: t('ftp-feature-mfe-token.token-import.apply-mapping-template'),
          onClick: handleApply,
          color: 'primary',
          isLoading,
          disabled: !selectedValue,
          guidingId: `${guidingId}-apply`,
        },
      ]}
      guidingId={guidingId}
      className={classes.modal}
      isScrollableContent
    >
      {loading ? (
        <Loading style={{ height: '30px' }} />
      ) : (
        <Box className={classes.radioGroupContainer}>
          <RadioGroup
            className={classes.radioGroup}
            value={selectedValue?.id}
            onChange={handleSelectionChange}
            data-guiding-id={`${guidingId}-radiogroup`}
          >
            {mappingsWithValidity.map((mapping, index) => (
              <FormControlLabel
                key={mapping.id}
                className={classes.radioItem}
                value={mapping.id}
                control={<Radio sx={{ height: 24 }} />}
                label={
                  <Box className={classes.labelContainer}>
                    <Typography className={classes.labelText}>
                      {mapping.mapping_name}
                    </Typography>
                    <Typography className={classes.labelText}>
                      {new Date(mapping.created_ts * 1000).toLocaleString()}
                    </Typography>
                  </Box>
                }
                data-guiding-id={`${guidingId}-mapping-${index}`}
              />
            ))}
          </RadioGroup>
        </Box>
      )}
    </ActionModal>
  )
}

export default ApplyMappingModal
