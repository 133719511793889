import { useTranslation } from 'react-i18next'
import { useNavigate } from 'react-router-dom'
import { tss } from 'tss-react/mui'

import { Typography } from '@mui/material'

import { ActionModal } from '@shared/components'

const useStyles = tss
  .withName('CancelTokenImportModal')
  .create(({ theme }) => ({
    modalBox: {
      width: '400px',
    },
    description: {
      color: theme.palette['neutral-700'],
      fontFamily: 'Inter',
      fontSize: '14px',
      fontStyle: 'normal',
      fontWeight: 400,
      lineHeight: '20px',
    },
  }))

export const CancelTokenImportModal = ({ open, handleClose, guidingId }) => {
  const { classes } = useStyles()
  const { t } = useTranslation()
  const navigate = useNavigate()

  const handleCancel = () => {
    navigate('/merchant/token-import')
  }

  return (
    <ActionModal
      open={open}
      onClose={handleClose}
      title={t('ftp-feature-mfe-token.token-import.cancel-token-import')}
      buttons={[
        {
          label: t('ftp-feature-mfe-token.token-import.cancel-import'),
          color: 'secondary',
          onClick: handleCancel,
          guidingId: `${guidingId}-cancel`,
        },
        {
          label: t('ftp-feature-mfe-token.token-import.continue-mapping'),
          color: 'primary',
          onClick: handleClose,
          guidingId: `${guidingId}-continue`,
        },
      ]}
      className={classes.modalBox}
      guidingId={guidingId}
    >
      <Typography
        id="cancel-token-import-modal-description"
        className={classes.description}
      >
        {t(
          'ftp-feature-mfe-token.token-import.cancel-token-import-back-main-page'
        )}
      </Typography>
    </ActionModal>
  )
}

export default CancelTokenImportModal
